import React from "react";

function Sec10() {
  return (
    <section className="features-area-three">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-7">
            <div className="section-title-two text-center mb-40 tg-heading-subheading animation-style1">
              {/* <span className="sub-title tg-element-title">
                What We Do For You
              </span> */}
              <h2 className="title tg-element-title">
                Why Should You Choose Us As Your Explainer Video Company In
                India?
              </h2>
              <p>
              Phanom Professionals a foremost explainer video company in India, is a pioneering name in the field of animated video production in India. Our Animated video experts are very well aware of the rising video boom and offer a range of explainer video services in India included animated videos, demo videos, corporate video and marketing video for businesses of all types and sizes. Our technology-led approach to explainer video products lends us an unparalleled edge in the industry. Being a well-recognized explainer video company in India, we have the skills and resources to perform the job proficiently and promisingly.
              </p>
            </div>
          </div>
        </div>

  
      </div>
    </section>
  );
}

export default Sec10;
