import React, { useEffect } from "react";
import Sec1 from "./Sec1";
import Sec4 from "./Sec4";

import Sec7 from "./Sec7";
import Sec8 from "./Sec8";

import Sec14 from "./Sec14";
import Sec9 from "./Sec9";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Testimonial from "../../CommonPages/HireIndianTalent/HireDeveloper/HireShopify/Testimonial";

function Branding() {
  
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Customize duration as needed
  }, []);

  return (
    <div>
      <Sec1/>
      <Sec14/>
      <Sec4/>
      <Sec7 />
      <Testimonial/>
      <Sec8 />
      <Sec9 />
    </div>
  );
}

export default Branding;
