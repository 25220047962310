import React, { useState } from "react";
import styled from "styled-components";
import { IoCheckmark } from "react-icons/io5";
import axios from "axios";
import { EXCHANGE_URLS } from "../../../Important/URLS";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TiPencil } from "react-icons/ti";
import * as Yup from "yup";
import Loader from "../../../Important/Loader";
import { hideLoader, showLoader } from "../../../../redux/users/action";

const schema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  delivery_time: Yup.string().required("Delivery time is required"),
  number_of_pages: Yup.string().required("Number of pages is required"),
  revision: Yup.string().required("Revisions is required"),
});
export default function Pricing() {
  const isLoading = useSelector((state) => state.isLoading);

  const gigId = useSelector((state) => state.users.gigId);
  const [data, setData] = useState({
    gig_id: gigId ? gigId.toString() : "",
    title: "",
    description: "",
    delivery_time: "",
    number_of_pages: "",
    revision: "",
    plugin_extension: 1,
    price: "",
    plan_type: "basic",
    content_upload: true,
  });
  const [data2, setData2] = useState({
    gig_id: gigId ? gigId.toString() : "",
    title: "",
    description: "",
    delivery_time: "",
    number_of_pages: "",
    revision: "",
    plugin_extension: 1,
    price: "",
    plan_type: "standard",
    content_upload: true,
  });
  const [data3, setData3] = useState({
    gig_id: gigId ? gigId.toString() : "",
    title: "",
    description: "",
    delivery_time: "",
    number_of_pages: "",
    revision: "",
    plugin_extension: 1,
    price: "",
    plan_type: "premium",
    content_upload: true,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appPricing = async () => {
    dispatch(showLoader());

    try {
      const axiosConfig = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const updatedData = { ...data, gig_id: gigId };
      const res = await axios.post(
        `${EXCHANGE_URLS}/gigspriceadd`,
        updatedData,
        axiosConfig
      );
      if (res?.status === 201) {
        navigate("/description");
      }
      const updatedData2 = { ...data2, gig_id: gigId };
      const res2 = await axios.post(
        `${EXCHANGE_URLS}/gigspriceadd`,
        updatedData2,
        axiosConfig
      );
      if (res2?.status === 201) {
        navigate("/description");
      }

      const updatedData3 = { ...data3, gig_id: gigId };
      const res3 = await axios.post(
        `${EXCHANGE_URLS}/gigspriceadd`,
        updatedData3,
        axiosConfig
      );
      if (res3?.status === 201) {
        navigate("/description");
        toast.success("Updated");
      }
    } catch (err) {
      toast.error("Firstly Fill Overview Page");
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleSubmit = () => {
    appPricing();
  };

  const handleDataChange = (newData, setDataFunc) => {
    setDataFunc(newData);
    schema
      .validate(newData, { abortEarly: false })
      .then(() => {})
      .catch((error) => {
        error.inner.forEach((err) => {
          console.log(err.message);
        });
      });
  };

  const numberOptions = Array.from({ length: 10 }, (_, i) => i + 1);
  const revisionOptions = Array.from({ length: 10 }, (_, i) => i + 1);

  return (
    <>
      {isLoading && <Loader />}

      <Root>
        <div className="div1">
          <header>
            <h3>Scope & Pricing</h3>
            <div className="flex_pack">
              <div className="switch_container">
                <span className="m_r_4">Offer packages</span>
                <label className="q1 q2">
                  <input type="checkbox" role="switch" className="toggler" />
                  <span className="span111">
                    <span className="span222">
                      <IoCheckmark />
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </header>
        </div>
        <div className="div2">
          <header>
            <span>Packages</span>
          </header>
        </div>
        {/* <div className="div3">
        <Table1
          data={data}
          setData={setData}
          data2={data2}
          setData2={setData2}
          data3={data3}
          setData3={setData3}
        />
        <Table2
          data={data}
          setData={setData}
          data2={data2}
          setData2={setData2}
          data3={data3}
          setData3={setData3}
        />
        <Table3
          data={data}
          setData={setData}
          data2={data2}
          setData2={setData2}
          data3={data3}
          setData3={setData3}
        />
        <Table5
          data={data}
          setData={setData}
          data2={data2}
          setData2={setData2}
          data3={data3}
          setData3={setData3}
        />
      </div> */}
        <div className="div3">
          <div className="column1">
            <div className="input_div"></div>
            <div className="input_div"></div>
            <div className="input_div"></div>
            <div className="input_div">delivery time</div>
            <div className="input_div">Number of pages</div>
            <div className="input_div">Plugin Extension</div>
            <div className="input_div">Content upload</div>
            <div className="input_div">Revisions</div>
            <div className="input_div2">Price</div>
            <div className="input_div2">Total Price</div>
          </div>

          <div className="column1" id="basic">
            <div
              className="input_div1"
              value={data.plan_type}
              onChange={(e) => setData({ ...data, plan_type: e.target.value })}
            >
              Basic
            </div>
            <div className="input_div">
              <textarea
                value={data.title}
                placeholder=" Basic Plan Title"
                id="basic"
                onChange={(e) =>
                  handleDataChange({ ...data, title: e.target.value }, setData)
                }
              />
              <span className="span_headd">
                <TiPencil />
              </span>
            </div>
            <div className="input_div">
              <textarea
                id="basic"
                value={data.description}
                placeholder="Description"
                onChange={(e) =>
                  handleDataChange(
                    { ...data, description: e.target.value },
                    setData
                  )
                }
              />
              <span className="span_headd">
                <TiPencil />
              </span>
            </div>
            <div className="input_div">
              <textarea
                type="number"
                id="basic"
                value={data.delivery_time}
                placeholder="Min 1 Day"
                onChange={(e) =>
                  handleDataChange(
                    { ...data, delivery_time: e.target.value },
                    setData
                  )
                }
              />
            </div>
            <div className="input_div">
              <select
                type="number"
                id="basic"
                value={data.number_of_pages}
                placeholder="min. 1 pages"
                onChange={(e) =>
                  handleDataChange(
                    { ...data, number_of_pages: e.target.value },
                    setData
                  )
                }
              >
                <option>Select</option>
                {numberOptions.map((option) => (
                  <>
                    <option key={option} value={option}>
                      {option}
                    </option>
                  </>
                ))}
              </select>
            </div>
            <div className="input_div">
              <input
                type="checkbox"
                checked={true}
                value={1}
                onChange={(e) =>
                  handleDataChange(
                    {
                      ...data,
                      plugin_extension: e.target.checked
                        ? parseInt(e.target.value)
                        : 1,
                    },
                    setData
                  )
                }
              />
            </div>
            <div className="input_div">
              <input
                id="basic"
                type="checkbox"
                checked={true}
                value={true}
                onChange={(e) =>
                  handleDataChange(
                    {
                      ...data,
                      content_upload: e.target.checked,
                    },
                    setData
                  )
                }
              />
            </div>
            <div className="input_div">
              <select
                type="number"
                id="basic"
                value={data.revision}
                placeholder="min. 1 time"
                onChange={(e) =>
                  handleDataChange(
                    { ...data, revision: e.target.value },
                    setData
                  )
                }
              >
                <option>Select</option>

                {revisionOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="input_div">
              <input
                type="number"
                value={data.price}
                onChange={(e) => setData({ ...data, price: e.target.value })}
              />
            </div>
            <div className="input_div">
              <input
                placeholder="in $"
                type="number"
                value={data.price}
                onChange={(e) => setData({ ...data, price: e.target.value })}
              />
            </div>
          </div>

          <div className="column1" id="standard">
            <div
              className="input_div1"
              value={data2.plan_type}
              onChange={(e) =>
                setData2({ ...data2, plan_type: e.target.value })
              }
            >
              Standard
            </div>
            <div className="input_div">
              <textarea
                value={data2.title}
                id="standard"
                placeholder="Standard Plan Title"
                onChange={(e) =>
                  handleDataChange(
                    { ...data2, title: e.target.value },
                    setData2
                  )
                }
              />
              <span className="span_headd">
                <TiPencil />
              </span>
            </div>
            <div className="input_div">
              <textarea
                id="standard"
                value={data2.description}
                placeholder="Description"
                onChange={(e) =>
                  handleDataChange(
                    { ...data2, description: e.target.value },
                    setData2
                  )
                }
              />
              <span className="span_headd">
                <TiPencil />
              </span>
            </div>
            <div className="input_div">
              <textarea
                type="number"
                id="standard"
                value={data2.delivery_time}
                placeholder="Min 1 Day"
                onChange={(e) =>
                  handleDataChange(
                    { ...data2, delivery_time: e.target.value },
                    setData2
                  )
                }
              />
            </div>
            <div className="input_div">
              <select
                type="number"
                id="standard"
                value={data2.number_of_pages}
                placeholder="min. 1 pages"
                onChange={(e) =>
                  handleDataChange(
                    { ...data2, number_of_pages: e.target.value },
                    setData2
                  )
                }
              >
                <option>Select</option>
                {numberOptions.map((option) => (
                  <>
                    <option key={option} value={option}>
                      {option}
                    </option>
                  </>
                ))}
              </select>
            </div>
            <div className="input_div">
              <input
                type="checkbox"
                checked={true}
                value={1}
                onChange={(e) =>
                  handleDataChange(
                    {
                      ...data2,
                      plugin_extension: e.target.checked
                        ? parseInt(e.target.value)
                        : 1,
                    },
                    setData2
                  )
                }
              />
            </div>
            <div className="input_div">
              <input
                id="standard"
                type="checkbox"
                checked={true}
                value={true}
                onChange={(e) =>
                  handleDataChange(
                    {
                      ...data2,
                      content_upload: e.target.checked,
                    },
                    setData2
                  )
                }
              />
            </div>
            <div className="input_div">
              <select
                type="number"
                id="standard"
                value={data2.revision}
                placeholder="min. 1 time"
                onChange={(e) =>
                  handleDataChange(
                    { ...data2, revision: e.target.value },
                    setData2
                  )
                }
              >
                <option>Select</option>

                {revisionOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="input_div">
              <input
                type="number"
                value={data2.price}
                onChange={(e) => setData2({ ...data2, price: e.target.value })}
              />
            </div>
            <div className="input_div">
              <input
                id="standard"
                type="number"
                value={data2.price}
                onChange={(e) => setData2({ ...data2, price: e.target.value })}
              />
            </div>
          </div>

          <div className="column1" id="premium">
            <div
              className="input_div1"
              value={data3.plan_type}
              onChange={(e) =>
                setData3({ ...data3, plan_type: e.target.value })
              }
            >
              Premium
            </div>
            <div className="input_div">
              <textarea
                id="premium"
                placeholder="Premium Plan Title"
                value={data3.title}
                onChange={(e) =>
                  handleDataChange(
                    { ...data3, title: e.target.value },
                    setData3
                  )
                }
              />
              <span className="span_headd">
                <TiPencil />
              </span>
            </div>
            <div className="input_div">
              <textarea
                id="premium"
                value={data3.description}
                placeholder="Description"
                onChange={(e) =>
                  handleDataChange(
                    { ...data3, description: e.target.value },
                    setData3
                  )
                }
              />
              <span className="span_headd">
                <TiPencil />
              </span>
            </div>
            <div className="input_div">
              <textarea
                type="number"
                id="premium"
                value={data3.delivery_time}
                placeholder="Min 1 Day"
                onChange={(e) =>
                  handleDataChange(
                    { ...data3, delivery_time: e.target.value },
                    setData3
                  )
                }
              />
            </div>
            <div className="input_div">
              <select
                type="number"
                id="premium"
                value={data3.number_of_pages}
                placeholder="min. 1 pages"
                onChange={(e) =>
                  handleDataChange(
                    { ...data3, number_of_pages: e.target.value },
                    setData3
                  )
                }
              >
                <option>Select</option>
                {numberOptions.map((option) => (
                  <>
                    <option key={option} value={option}>
                      {option}
                    </option>
                  </>
                ))}
              </select>
            </div>
            <div className="input_div">
              <input
                type="checkbox"
                checked={true}
                value={1}
                onChange={(e) =>
                  handleDataChange(
                    {
                      ...data3,
                      plugin_extension: e.target.checked
                        ? parseInt(e.target.value)
                        : 1,
                    },
                    setData3
                  )
                }
              />
            </div>
            <div className="input_div">
              <input
                id="premium"
                type="checkbox"
                checked={true}
                value={true}
                onChange={(e) =>
                  handleDataChange(
                    {
                      ...data3,
                      plugin_extension: e.target.checked,
                    },
                    setData3
                  )
                }
              />
            </div>
            <div className="input_div">
              <select
                type="number"
                id="premium"
                value={data3.revision}
                placeholder="min. 1 time"
                onChange={(e) =>
                  handleDataChange(
                    { ...data3, revision: e.target.value },
                    setData3
                  )
                }
              >
                <option>Select</option>
                {revisionOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="input_div">
              <input
                id="premium"
                type="number"
                value={data3.price}
                onChange={(e) => setData3({ ...data3, price: e.target.value })}
              />
            </div>
            <div className="input_div">
              <input
                type="number"
                value={data3.price}
                onChange={(e) => setData3({ ...data3, price: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div className="div4">
          <a type="button" role="button" href="/gigs">
            Cancel
          </a>
          {(data.title.trim() !== "" &&
            data.description.trim() !== "" &&
            data.delivery_time.trim() !== "" &&
            data.number_of_pages.trim() !== "" &&
            data.revision.trim() !== "" &&
            data.price.trim() !== "") ||
          (data2.title.trim() !== "" &&
            data2.description.trim() !== "" &&
            data2.delivery_time.trim() !== "" &&
            data2.number_of_pages.trim() !== "" &&
            data2.revision.trim() !== "" &&
            data2.price.trim() !== "") ||
          (data3.title.trim() !== "" &&
            data3.description.trim() !== "" &&
            data3.delivery_time.trim() !== "" &&
            data3.number_of_pages.trim() !== "" &&
            data3.revision.trim() !== "" &&
            data3.price.trim() !== "") ? (
            <button
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </button>
          ) : null}
        </div>
      </Root>
    </>
  );
}
const Root = styled.section`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 50px;
  padding: 0px 40px;
  width: 75vw;
  .div1 {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 22px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(218, 219, 221);
    header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      h3 {
        line-height: 130%;
        font-weight: 400;
        color: #404145;
        font-size: 28px;
      }
      .flex_pack {
        position: relative;
        display: flex;
        color: #62646a;
        .switch_container {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          font-size: 14px;
          cursor: pointer;
          margin-left: 8px;
          .m_r_4 {
            margin-left: 8px;
          }
          .q1,
          .q2 {
            display: inline-flex;
            line-height: 24px;
            user-select: none;
            .toggler {
              display: flex;
              pointer-events: none;
              margin-left: 8px;
            }

            input {
              opacity: 0;
              width: 0px;
              height: 0px;
              overflow: hidden;
              box-shadow: none !important;
            }
            input[type="checkbox"] {
              vertical-align: bottom;
            }

            .span111 {
              background: rgb(34, 35, 37);
              height: 24px;
              width: 40px;
              display: inline-flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              flex-direction: column;
              position: relative;
              border-radius: 999px;
              cursor: pointer;
              transition: all 0.1s cubic-bezier(0.75, 0, 0.25, 1) 0s;
            }
            .span222 {
              left: 16px;
              align-self: flex-end;
              border-color: rgb(34, 35, 37);
              width: 20px;
              height: 20px;
              position: absolute;
              margin: 2px;
              background: rgb(255, 255, 255);
              border-radius: 50%;
              display: inline-flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              transition: all 0.1s cubic-bezier(0.75, 0, 0.25, 1) 0s;
              svg {
                transition: 0.1s cubic-bezier(0.75, 0, 0.25, 1);
                opacity: 1;
                width: 12;
                height: 12;
                fill: rgb(34, 35, 37);
              }
            }
          }
        }
      }
    }
  }
  .div2 {
    text-align: start;
    width: 100%;
    header {
      font-weight: 600;
      padding-top: 12px;
      padding-bottom: 16px;
      color: #62646a;
    }
  }
  .div3 {
    margin-bottom: 52px;
  }
  .div3 {
    margin-bottom: 52px;
    display: flex;
    border: 1px solid lightgray;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: inherit;
    overflow-x: scroll;

    .column1 {
      display: flex;
      flex-direction: column;
      flex: 1;
      select {
        width: 205px;
        outline: none;
        border: none;
        color: #7a7d85;
        background-color: transparent;
      }
      .input_div2 {
        font-weight: 600;
        background-color: #f5f5f5;
        border-top: 1px solid lightgray;
      }
      .input_div {
        background-color: #fff;
        border-bottom: 1px solid lightgray;
      }
      .input_div1 {
        text-align: center;
        font-weight: 600;
        padding: 8px;
        border-bottom: 1px solid lightgray;
      }
      .input_div,
      .input_div2,
      .input_div1 {
        width: 100%;
        height: 60px;
        border-right: 1px solid lightgray;
        padding: 10px;
        color: #7a7d85;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        input {
          border: 1px solid lightgray;
          border-radius: 10px;
          padding: 2px;
        }
        textarea {
          width: 100%;
          padding-right: 24px;
          border: none;
          font-size: 13px;
          color: #7a7d85;
          border-radius: 3px;
          outline: none;
        }
        .span_headd {
          text-align: end;
          svg {
            width: 14px;
            height: 14px;
            fill: rgb(197, 198, 201);
          }
        }
      }
    }
  }
  .div4 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    a,
    button {
      padding: 11px 20px;
      -webkit-box-pack: center;
      justify-content: center;
      align-items: center;
      gap: 8px;
      box-sizing: border-box;
      position: relative;
      border-radius: 8px;
      font-weight: 600;
      line-height: 24px;
      font-size: 16px;
      text-decoration: none;
      cursor: pointer;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      transition: 70ms cubic-bezier(0.75, 0, 0.25, 1);
    }
    a {
      color: #222325;
      border: 1px solid #e4e5e7;
    }
    button {
      background: #222325;
      border: 1px solid #fff;
      color: #fff;
    }
  }

  @media (max-width: 567px) {
    margin: 0;
    padding: 5px;
    width: 100vw;
  }

  @media (min-width: 567px) and (max-width: 992px) {
    margin: 0;
    padding: 30px;
    width: 100vw;
    overflow-x: scroll;
  }
`;
