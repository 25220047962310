import React from "react";






function Sec4(){

  return (
    <>
    <section className="features-area-three">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-12 col-lg-7">
          <div className="section-title-two text-center mb-40 tg-heading-subheading animation-style1">
        
            <h2 className="title tg-element-title">
            We Provide YouTube Marketing Services
    
            </h2>

    
          </div>
        </div>
      </div>
    
      <div className="features-item-wrap-two">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="features-item-three">
              <div className="features-icon-three">
                <i className="flaticon-inspiration"></i>
              </div>
              <div className="features-content-three">
                <h2 className="title">Organic YouTube Management</h2>
                <p>
                Organic Youtube management allows you to manage and rank your videos on Google and Youtube's main pages.                </p>
              </div>
            </div>
          </div>
    
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="features-item-three">
              <div className="features-icon-three">
                <i className="flaticon-inspiration"></i>
              </div>
              <div className="features-content-three">
                <h2 className="title">Paid YouTube Promotion Services</h2>
                <p>
                In this manage, our YouTube advertising agency offers you paid YouTube advertising strategies that will help you fulfil your YouTube advertising purpose.                 </p>
              </div>
            </div>
          </div>
      
    
        </div>
 
    
      </div>
    </div>
    </section>

    <section className="features-area-three">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-12 col-lg-7">
          <div className="section-title-two text-center mb-40 tg-heading-subheading animation-style1">
        
            <h2 className="title tg-element-title">
            Services For Organic YouTube Marketing Include
    
            </h2>

    
          </div>
        </div>
      </div>
    
      <div className="features-item-wrap-two">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="features-item-three">
              {/* <div className="features-icon-three">
                <i className="flaticon-inspiration"></i>
              </div> */}
              <div className="features-content-three">
              <p>Generating Keywords and a Title</p>
                <p>Creating Your Personalized YouTube Channel</p>
                <p>#Tag Research and Card Addition in Videos</p>

              </div>
            </div>
          </div>
    
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="features-item-three">
              {/* <div className="features-icon-three">
                <i className="flaticon-inspiration"></i>
              </div> */}
              <div className="features-content-three">
              <p>
              Audio/video to text (transcription)</p>
                <p>Sharing Videos</p>
                <p>
                Video Rank Monitoring</p>
              </div>
            </div>
          </div>
      
    
        </div>
 
    
      </div>
    </div>
    </section>
    </>
  );
}

export default Sec4;
