import React from "react";

function Sec4() {
  return (
    <section className="features-area-three">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-7">
            <div className="section-title-two text-center mb-40 tg-heading-subheading animation-style1">
              {/* <span className="sub-title tg-element-title">
              What We Do For You
            </span> */}
              <h2 className="title tg-element-title">
                Persuade Your Potential Of Possibilities With Magento
                Development Services in India
              </h2>
              <p>
                For creating your imprint in the market, whether it is of any
                size we are the one you can take in adherence under Magento
                Development Services, India, get you fortune with one step
                closer covering all features and effectiveness.
              </p>
            </div>
          </div>
        </div>

        <div className="features-item-wrap-two">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  {/* <h2 className="title">Zero Billing Guarantee</h2> */}
                  <p>
                  Consulting – Encountering the open – Podium to elicit your idea or dream project.At this stage, we come up with workflows and define a technology stack.


                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  {/* <h2 className="title">On-Time Project Delivery</h2> */}
                  <p>
                  UI & UX design - Our research of a target user base underwrites to a transformation - determined UI and UX development.


                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  {/* <h2 className="title">Strict Adherence to NDA</h2> */}
                  <p>
                  Integration - By integration of magento with different systems including Product Information Management (PIM), CRM etc. will assist your business.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-4 col-md-12">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  {/* <h2 className="title">Value-Based Engagement Models</h2> */}
                  <p>
                  Testing & Development - For secure solution with faster and in high quality application run, custom made functions are well tested and developed accordingly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  {/* <h2 className="title">Agile Development Approach</h2> */}
                  <p>
                  Training - We provide user training that keep up your web store team to swiftly get used to handle Magento.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  {/* <h2 className="title">Dedicated Shopify Developers</h2> */}
                  <p>
                  Evolution - With simultaneous support and evolution of technology we make sure to grow along with your evolving business needs.
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="col-xl-3 col-lg-4 col-md-12">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <p>
                    JSF - Java Server Faces (JSF) is a new standard Java
                    framework for building Web applications, by providing a
                    component-centric our approach to developing Java Web user
                    interfaces.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-12">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <p>
                    JSF - Java Server Faces (JSF) is a new standard Java
                    framework for building Web applications, by providing a
                    component-centric our approach to developing Java Web user
                    interfaces.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sec4;
