import React, { useEffect, useState } from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import { toast } from "react-toastify";
import axios from "axios";
import { EXCHANGE_URLS } from "../../../../../Important/URLS";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import photo from "../../../../../Images/photo.webp";

export default function Preview() {
  let { cd } = useParams();

  const [previewData, setPreviewData] = useState(null);
  useEffect(() => {
    const getPreviewApi = async () => {
      try {
        const axiosConfig = {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        const res = await axios.get(
          `${EXCHANGE_URLS}/sellerPreview/${cd}`,
          axiosConfig
        );
        if (res?.status === 201) {
          setPreviewData(res?.data?.message[0] || []);
        }
      } catch (err) {
        toast.error(err, "Error");
      }
    };

    getPreviewApi();
  }, [cd]);

  return (
    <Root>
      <div className="section">
        <Section1 previewData={previewData} />
        <Section3 previewData={previewData} />
        <Section2 previewData={previewData} />
      </div>
      <div className="below_section">
        <div className="sub_div_two">
          <div className="contact_div">
            <div className="contact_profile_div">
              <img src={photo} alt="img" />
              <div className="contact_profile_content">
                <h2>{previewData?.username}</h2>
                <p>online • 04:19 PM local time</p>
              </div>
            </div>

            <div className="contact_btn">
              <a href="/">Contact</a>
            </div>

            <div className="average_time">
              <p>Average response time: 1 hour</p>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  display: flex;
  width: 100vw;
  .section{
    flex-direction: column;
    width: 70vw;
  }
  .below_section{
    width: 30vw;
  }
  .sub_div_two {
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;

    .contact_div {
      height: 267px;
      position: fixed;
      /* width: 84%; */
      border-radius: 20px;
      border: 1px solid #e4e5e7;
      padding: 32px;
      box-shadow: 0 1.2px 2px rgba(0, 0, 0, 0.02),
        0 2px 3.68135px rgba(0, 0, 0, 0.04), 0 1px 5px rgba(0, 0, 0, 0.05);
      .contact_profile_div {
        display: flex;
        gap: 20px;
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
        }
        .contact_profile_content {
          display: flex;
          flex-direction: column;
          h2 {
            font-size: 18px;
            font-weight: 700;
            color: #404145;
            margin-bottom: 0;
          }
          p {
            color: #62646a;
            font-size: 17px;
            margin-top: 5px;
          }
        }
      }

      .contact_btn {
        margin-top: 20px;
        width: 100%;
        a {
          background-color: #222325;
          padding: 13px 100px;
          border-radius: 10px;
          color: white;
          text-decoration: none;
          font-size: 18px;
          font-weight: 500;
        }
      }

      .average_time {
        margin-top: 30px;
        border-top: 1px solid #e4e5e7;
        display: flex;
        padding: 20px;
        justify-content: center;
        p {
          color: #62646a;
          font-size: 15px;
        }
      }
    }
  }
`;
