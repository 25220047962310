import React from "react";


function Sec9() {
  return (
    <section className="features-area-three">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-7">
            <div className="section-title-two text-center mb-40 tg-heading-subheading animation-style1">
              {/* <span className="sub-title tg-element-title">
              What We Do For You
            </span> */}
              <h2 className="title tg-element-title">
                Hire the Best Logo Designer.
              </h2>
              <p>Experience an incredible logo For Your Brand</p>
              <p>
                200+ talented Desginer are waiting for you. Just add your touch
              </p>
            </div>
            
          </div>
        </div>

      </div>
    </section>
  );
}

export default Sec9;
