import React from "react";

function Sec10() {
  return (
    <section className="features-area-three">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-7">
            <div className="section-title-two text-center mb-40 tg-heading-subheading animation-style1">
              {/* <span className="sub-title tg-element-title">
                What We Do For You
              </span> */}
              <h2 className="title tg-element-title">
              We Imprint With Contagious Print Design Services in Mohali

              </h2>
              <p>
              We Imprint With Contagious Print Design Services in Mohali
              Comprising optimal utilization with the set strategies to blend in and give greater term to your business via our designed websites.
              </p>
            </div>
          </div>
        </div>

        <div className="features-item-wrap-two">

          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Catalogues</h2>
                  <p>
                  Catalogs This glistening booklets from your favorite shops that fill your mailbox are catalogs,Clique customer are attracted for higher performance & maintaining demands.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Brochures</h2>
                  <p>
                  A great way to anthology of information in a simple, eye-catching design that attracts potential clients by offering basic information we bring expert of creative artwork.


                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Menu</h2>
                  <p>
                  Menu This includes eradicating scratch, dust busting, and rigs etc.make you satisfied with the menu designs in the frame. Different formats and styles you can choose.


                  </p>
                </div>
              </div>
            </div>

          </div>

          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Magazine</h2>
                  <p>
                  We spot all the smart trends for brand with time whether it is compositing;Professional magazine designers are ready to create the perfect magazine. services we do it all.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Flyer</h2>
                  <p>
                  Flyer Using flyers to bring in new consumers to join via promotion. We release a variety of flyers for ad campaign to add variety and an imprint on viewers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Advertisement</h2>
                  <p>
                  Advertisement Our extraordinary advertising methodology is life-like expression to move with people in the real world that comprehend the launch with environs.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Signage Design</h2>
                  <p>
                    They are trivial in nature, help you put across your
                    creative ideas or business ads on variety of social media
                    platforms and for the people who travel a lot, signage helps
                    to promote.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Design For Stationery</h2>
                  <p>
                    We are especially helpful for your small scale promotional
                    approaches since the imprints are visualized by the users
                    how you want the project your services to them as effective
                    as they can{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6"></div>
            <div className="col-xl-3 col-lg-4 col-md-6"></div>
          </div> */}
          
        </div>
      </div>
    </section>
  );
}

export default Sec10;
