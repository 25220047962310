import React from "react";
import styled from "styled-components";
import { secGradient } from "../../../../../GlobalStyles";

export default function Section4() {
  return (
    <Root>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-lg-1 col-md-12 col-12"></div>

          <div className="col-lg-10 col-md-12 col-12 text_area">
            <span>Why Hire </span>{" "}
            <span className="text"> Android developer</span>
            <span> with Phanom?</span>
            <p>
              Phanom Professionals prides itself on offering pinnacle-tier
              Android development services that cater to a range of needs.
              Explore the array of offerings provided through our pro-Android
              developers, every one crafted with precision and innovation to
              propel your cell app endeavors to new heights.
            </p>
          </div>

          <div className="col-lg-1 col-md-12 col-12"></div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-12 col-12 block_main">
            <div className="block_div">
              <p> Custom Android App Development:</p>
              <div className="mini_block">
                <div>
                  <span>
                    Our Android developers concentrate on creating bespoke
                    mobile programs tailor-made to fulfill your particular
                    business requirements. Whether you need an employer-stage
                    app to streamline inner approaches or a purchaser-facing
                    application to decorate consumer engagement, our developers
                    are adept at translating your imaginative and prescient into
                    a fully functional and user-pleasant Android app.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 block_main">
            <div className="block_div">
              <p>UI/UX Design Excellence:</p>
              <span>
                Aesthetics and usability are paramount inside the cell app
                landscape. Our Android builders recognise handing over a
                wonderful user interface (UI) and consumer enjoyment (UX) to
                make certain that your app now not handiest seems visually
                attractive but additionally affords an intuitive and seamless
                navigation experience. From wireframing to prototyping, we pay
                meticulous interest to every design detail.
              </span>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 block_main">
            <div className="block_div">
              <p>Cross-Platform Compatibility:</p>
              <span>
                Recognizing the various surroundings of cell devices, our
                Android developers use move-platform improvement frameworks
                consisting of Flutter or React Native. This technique ensures
                that your app can reach a wider audience with the aid of
                seamlessly performing on each Android and iOS platform. By
                maximizing code reusability, we streamline the development
                manner without compromising on performance.
              </span>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-12 col-12 block_main">
            <div className="block_div">
              <p> App Integration and Upgradation:</p>
              <div className="mini_block">
                <div>
                  <span>
                    In a dynamic virtual panorama, your app needs to stay
                    modern-day and incorporate present-day technologies. Our
                    Android developers excel in seamlessly integrating
                    third-party APIs, price gateways, and other vital
                    functionalities. Moreover, we offer non-stop assistance for
                    app improvements, making sure that your utility remains
                    well-suited to trendy Android variations and gadgets.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 block_main">
            <div className="block_div">
              <p>Enterprise Android Solutions:</p>
              <span>
                For agencies looking to decorate productivity and streamline
                operations, our Android builders concentrate on creating strong
                enterprise answers. From employee management apps to custom CRM
                systems, we apprehend the specific demanding situations faced by
                organizations, and our builders are adept at growing tailor-made
                solutions that address those challenges effectively.
              </span>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 block_main">
            <div className="block_div">
              <p>E-commerce App Development:</p>
              <span>
                With the growing prominence of mobile commerce, having a sturdy
                e-commerce app is critical for organizations trying to faucet
                into a good-sized cellular client base. Our Android developers
                are experienced in creating secure and characteristic-wealthy
                e-trade applications, integrating steady price gateways and
                making sure an easy and secure purchasing revel into your users.
              </span>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-12 col-12 block_main">
            <div className="block_div">
              <p> Maintenance and Support:</p>
              <div className="mini_block">
                <div>
                  <span>
                    Beyond the initial development segment, our dedication to
                    your app's achievement extends to ongoing upkeep and
                    guidance. Our Android builders proactively display your
                    app's overall performance, address any rising problems right
                    away, and enforce updates to ensure ideal functionality.
                    This complete aid ensures that your app keeps supplying fees
                    to users over a long time.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 block_main">
            <div className="block_div">
              <p>
                Augmented Reality (AR) and Virtual Reality (VR) Integration:
              </p>
              <span>
                For groups looking to offer an immersive and modern-day
                experience, our Android developers are properly versed in
                integrating AR and VR technologies into mobile packages. Whether
                it is improving product visualization or creating interactive
                schooling modules, we harness the electricity of AR and VR to
                elevate your app's skills.
              </span>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 block_main">
            <div className="block_div">
              <p> Secure and Scalable Solutions:</p>
              <span>
                Security is a non-negotiable issue of mobile app development.
                Our Android developers enforce strong safety features to protect
                personal records and touchy information. Moreover, we design
                apps with scalability in thoughts, making sure that they can
                develop seamlessly along your business without compromising on
                performance.
              </span>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-12 col-12 block_main">
            <div className="block_div">
              <p>Transparent Communication and Project Management:</p>
              <div className="mini_block">
                <div>
                  <span>
                  Effective collaboration is at the center of our careers. Our Android builders prioritize transparent communique during the improvement procedure. Regular updates, milestone evaluations, and collaborative choice-making make sure that you are involved and informed at every stage of your app's improvement.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 block_main"></div>
          <div className="col-lg-4 col-md-12 col-12 block_main"></div>
        </div>
    


        <p className="text-center">
Phanom Professionals' Android developers deliver a wealth of understanding to the table, imparting a complete suite of offerings to convert your app thoughts into reality. Whether you're a startup venturing into the cellular app space or a longtime business enterprise looking to redesign your virtual presence, our Android improvement services are designed to meet your unique wishes and propel your commercial enterprise forward with mobile-first technology.
</p>
<div class="cont_sub_btn mt-3 mb-3">
          <a href="#"> Ready to hire? </a>
        </div>


      </div>
    </Root>
  );
}
const Root = styled.section`
  padding: 0 50px;

        .cont_sub_btn {
    display: flex;
    justify-content: center;
 a {
    font-size:14px;
    color:white;
    background-color:#13c9df;
    padding:19px 31px;
    border:0;
    letter-spacing:3px;
    border-radius: 50px;
    transition: 0.2s;
    font-weight: 600;
    &:hover{
      background-color:#ececec;
      color:#13c9df;
    }
}
} 

  .text_area {
    margin-bottom: 30px;
    padding: 20px 50px;
    text-align: center;
    .text {
      background: ${secGradient};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      font-size: 35px;
      line-height: 1.3em;
    }
    span {
      font-weight: bold;
      font-size: 35px;
      line-height: 1.3em;
    }
    p {
      text-align: center;
      color: #6e6e6e;
      padding: 20px;
      word-spacing: 1px;
    }
  }
  .block_main {
    margin-bottom: 20px;
    display: flex;
    flex: 1;
    span {
      color: #6e6e6e;
    }
    .block_div {
      padding: 50px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border: 1px solid #dadada;
      border-radius: 30px;
      &:hover {
        background-color: #dadada4f;
      }
      p {
        font-size: 25px;
        font-weight: bold;
        margin: 0;
      }
      .mini_block {
        display: flex;
        .border-bottom {
          padding-bottom: 30px;
          border-bottom: 1px solid #dadada;
        }
        svg {
          color: #147888;
          width: 20px;
          height: 20px;
          font-weight: 600;
          margin: 10px;
        }
      }
    }
  }
  @media (max-width: 567px) {
    padding: 0;

    .text_area {
      padding: 0;
      margin-bottom: 0;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    padding: 0;
    .text_area {
      padding: 0;
      margin-bottom: 0;
    }
   .block_main {
   flex: unset; 
}
  }
`;
