import React from 'react'
import baby from "../../../assets/img/custom/baby.jpg";
import goal from "../../../assets/img/custom/gaol.jpg";
import dot from "../../../assets/img/custom/dot.png";


function Sec11() {
  return (
    <section className="overview-area pt-4 pb-120">
      {/* <div
        className="overview-shape"
        data-aos="fade-left"
        data-aos-delay="200"
      ></div> */}
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="overview-img-wrap">
              <img src={baby} alt="" />
            <img src={goal} alt="" data-parallax='{"x" : 50 }' />
            <img src={dot} alt="" /> 
              <div className="icon">
                <i className="flaticon-report-1"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="overview-content">
              <div className="section-title-two mb-20 tg-heading-subheading animation-style3">
                {/* <span className="sub-title">Company Overview</span> */}
                {/* <h2 className="title tg-element-title">
                WHAT IS PAY PER CLICK AND HOW DOES IT WORK?
                </h2> */}
                <p className="info-one">
                We have many marketing strategies that can bring a new fire to your business with great intensification. To create organic revenue and have a ranking position in the search engine, many tactics need to be applied. In a day, growth is not possible, but we strive to make instant results with the highest possible result value. Our market specialists are always ready to be engaged in making great profits and market growth for businesses. Choose us to entrust your marketing and e-commerce campaign with us.              </p>
              
              </div>
        
              {/* <div className="content-bottom">
              <ul className="list-wrap">
                <li>
                  <div className="icon">
                    <i className="flaticon-trophy"></i>
                  </div>
                  <div className="content">
                    <h2 className="count">
                      <Odometer end={235} />+
                    </h2>
                    <p>Best Award</p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <i className="flaticon-rating"></i>
                  </div>
                  <div className="content">
                    <h2 className="count">
                      <Odometer end={98} />k
                    </h2>
                    <p>Happy Clients</p>
                  </div>
                </li>
              </ul>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Sec11