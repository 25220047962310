import styled from "styled-components";
import Navbarr from "./Navbarr";
import { useSelector } from "react-redux";
import PostNavBuyer from "./PostNavBuyer";
import PostNavSeller from "./PostNavSeller";
import Footer from "./Footer";

export default function Layout({ children }) {
  const userCheck = useSelector((state) => state?.users?.userCheck);
  const userDetails = useSelector((state) => state?.users.user);
  const token = localStorage.getItem("token");
  return (
    <Root>
      <div className="top_bar">
        {userCheck && token ? (
          userDetails.role === "buyer" ? (
            <PostNavBuyer />
          ) : userDetails.role === "seller" ? (
            <PostNavSeller />
          ) : (
            <Navbarr />
          )
        ) : (
          <Navbarr />
        )}
      </div>
      <div className="main_body">{children}</div>
      <Footer/>
    </Root>
  );
}

const Root = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;

  .main_bar {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .top_bar {
      background: #ffffff;
      display: flex;
      position: fixed;
      z-index: 1111;
      height: auto;
      background-image: linear-gradient(
        to bottom right,
        #c6a5ffc7,
        #c6a5ff63,
        #ffffff,
        #c6a5ff24
      );
      width: 100%;
    }
    .main_body {
      height: 100%;
      width: 100%;
    }
  }
`;
