import React from 'react'

function Sec14() {
  return (
    <section className="features-area-three">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-12 col-lg-7">
          <div className="section-title-two text-center mb-40 tg-heading-subheading animation-style1">
            {/* <span className="sub-title tg-element-title">
              What We Do For You
            </span> */}
            <h2 className="title tg-element-title">
            We Entrust To-Provide You On Your Mark Performance With :
            </h2>
          </div>
        </div>
      </div>

      <div className="features-item-wrap-two">
        <div className="row justify-content-center">
          <div className="col-xl-3 col-lg-3 col-md-6">
            <div className="features-item-three">
              <div className="features-icon-three">
                <i className="flaticon-inspiration"></i>
              </div>
              <div className="features-content-three">
                <h2 className="title">Expertise</h2>
                <p>
                    We naturally reconcile the deployed number for the utmost websites that knack the performance-based competence. We ensure our clients present your business with what the market desire and the best numbers for visiting your website.                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-6">
            <div className="features-item-three">
              <div className="features-icon-three">
                <i className="flaticon-inspiration"></i>
              </div>
              <div className="features-content-three">
                <h2 className="title">Culpability</h2>
                <p>
                    Even if you are starting from scratch we are right and right here to formulate your website thoroughly for everything it is needed to make it worth. With a higher rate of sale & services on your website are made free from any obligatory aspect.                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6">
            <div className="features-item-three">
              <div className="features-icon-three">
                <i className="flaticon-inspiration"></i>
              </div>
              <div className="features-content-three">
                <h2 className="title">
                Acquaintance</h2>
                <p>
                Our proficiency is always with tetchy aspect and upgrading with the trends on going in the market to make everything new and catchy for the benefit of your website. Software designed for your website definitely assists to become Savvy.                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6">
            <div className="features-item-three">
              <div className="features-icon-three">
                <i className="flaticon-inspiration"></i>
              </div>
              <div className="features-content-three">
                <h2 className="title">Approach</h2>
                <p>
                We prepare a line of attack with some easy and understandable creation for your website for avoiding any snags. Rightfully creating your website and making it for the best use of the visitors and active users for best-boosted results, our team have the potential to strive.                </p>
              </div>
            </div>
          </div>

        </div>
 

      </div>
    </div>
  </section>
  )
}

export default Sec14