import React from "react";

function Sec6() {
  return (
    <section className="features-area-three">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-7">
            <div className="section-title-two text-center mb-40 tg-heading-subheading animation-style1">
              {/* <span className="sub-title tg-element-title">
                What We Do For You
              </span> */}
              <h2 className="title tg-element-title">
              Our Work
              </h2>
              {/* <p>
              Acquire our services
              </p> */}
            </div>
          </div>
        </div>

        <div className="features-item-wrap-two">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Project Name: Stargirl

                  </h2>
                  <p>
                  We delivered 143 out of the 250+ VFX shots for this historical war movie, including roto mattes for the actors, crowds and other assets to acquire the desired output. In this particular shot, we provided roto mattes to replace the background with a bunch of fighter planes.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Project Name: Sabrina

                  </h2>
                  <p>
                  We produced the roto for the lady with the hair strands and replaced the background visual of the open city view with elements like the sky, rivers, cliffs and houses (as well as the kid standing next to her).
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Project Name: Crisis
                  </h2>
                  <p>
                  With over 110 shots on our plate, here’s a preview of a shot where our compositors changed the background matte paintings with the help of roto mattes.                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Superman And Lois

                  </h2>
                  <p>
                  Our skillful Roto artists have helped Superman by generating his matte to allow him to perform his heroics. We roto’d around 150+ shots till now, still counting.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Pocket Calendar Card Design</h2>
                  <p>
                    Our stunning illustrations for making the visuals more
                    appealing that can be used for commercials or ads, for
                    customers get used to, we own a grate adherence of the same.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Bill Board Design</h2>
                  <p>
                    For profile-raising or marketing purposes our product
                    graphical illustrations are far better among others that we
                    create detailed of products to emulsify on boards.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Trade Banner Design</h2>
                  <p>
                    Making appealing content to help your potential to
                    visualize, the Brand Value that you are going to serve your
                    customers of all high-quality is presented by us to promote
                    at remote level too.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Car Wrap Design</h2>
                  <p>
                    Means of communicating ideas and stories our experts are all
                    well versed and we postulate personalized cut-outs for the
                    moving advertisement of your brand services.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Signage Design</h2>
                  <p>
                    They are trivial in nature, help you put across your
                    creative ideas or business ads on variety of social media
                    platforms and for the people who travel a lot, signage helps
                    to promote.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Design For Stationery</h2>
                  <p>
                    We are especially helpful for your small scale promotional
                    approaches since the imprints are visualized by the users
                    how you want the project your services to them as effective
                    as they can{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6"></div>
            <div className="col-xl-3 col-lg-4 col-md-6"></div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Sec6;
